
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { Inhibitor } from "../../models/reports/inhibitor";
import { formatDecimal, formatFinancialAmounts } from "../../utils/format";

export default Vue.extend({
  name: "FVIIIperCountry",
  data: () => ({
    dataTable: [] as Inhibitor[],
    isLoading: true,
  }),
  computed: {
    selectedSurvey() {
      return this.$store.getters["agsreport/getYearSelected"];
    },
    headers() {
      return [
        {
          text: "N",
          value: "n",
        },
        {
          text: i18n.t("reports.agsreports.yearselected"),
          value: "surveyYear",
        },
        {
          text: i18n.t("basic.country"),
          value: "country",
        },
        {
          text: i18n.t("reports.agsreports.FVIIIperCountry.FVIII_Total"),
          value: "total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.agsreports.FVIIIperCountry.FVIII_Plasma_derived"
          ),
          value: "plasmaDerived",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t("reports.agsreports.FVIIIperCountry.FVIII_recombinant"),
          value: "recombinant",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.agsreports.FVIIIperCountry.FVIII_recombinant_extended_half_life"
          ),
          value: "recombinantEx",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.agsreports.FVIIIperCountry.FVIII_Humanitarian_Aid"
          ),
          value: "population",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t("reports.agsreports.FVIIIperCountry.FVIII_per_capita"),
          value: "perCapita",
          formatter: (val: number): number | string => formatDecimal(val, 6),
        },
        {
          text: i18n.t(
            "reports.agsreports.FVIIIperCountry.FVIII_per_capita_without_hum_aid"
          ),
          value: "perCapitaWODonated",
          formatter: (val: number): number | string => formatDecimal(val, 6),
        },
        {
          text: i18n.t(
            "reports.agsreports.FVIIIperCountry.Percent_Plasma_derived"
          ),
          value: "plasmaDerivedPerc",
          formatter: (val: number): number | string => formatDecimal(val),
        },
        {
          text: i18n.t(
            "reports.agsreports.FVIIIperCountry.Percent_recombinant"
          ),
          value: "recombinantPerc",
          formatter: (val: number): number | string => formatDecimal(val),
        },
        {
          text: i18n.t(
            "reports.agsreports.FVIIIperCountry.Percent_recombinant_extended_half_life"
          ),
          value: "recombinantExPerc",
          formatter: (val: number): number | string => formatDecimal(val),
        },
      ];
    },
  },
  methods: {
    getData(year: number) {
      this.isLoading = true;
      axios
        .get(`report/wfh/TreatmentByCountry/1`, { params: { year: year } })
        .then((res) => {
          this.dataTable = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(
          `report/wfh/TreatmentByCountry/1/export?year=${this.selectedSurvey.year}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileName = `${i18n.t(
            "navbar.menu.reportsub.ags_reports"
          )} - ${i18n.t("reports.agsreports.FVIIIperCountry.title")}`;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "aplication/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.selectedSurvey.year) {
      this.getData(this.selectedSurvey.year);
    }
  },
  watch: {
    selectedSurvey() {
      this.getData(this.selectedSurvey.year);
    },
  },
});
